import { bindable, inject }       from 'aurelia-framework';
import { I18N }                   from 'aurelia-i18n';
import { BaseListViewModel }      from 'base-list-view-model';
import { FilterFormSchema }       from 'modules/administration/maintenances/filter-form-schema';
import { MaintenancesRepository } from 'modules/administration/maintenances/services/repository';
import { AppContainer }           from 'resources/services/app-container';
import { Downloader }             from 'resources/services/downloader';

@inject(AppContainer, MaintenancesRepository, I18N, Downloader, FilterFormSchema)
export class ListMaintenances extends BaseListViewModel {

    listingId = 'administration-maintenances-listing';

    @bindable headerTitle    = 'listing.administration.maintenances';
    @bindable newRecordRoute = 'administration.maintenances.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.maintenances.manage',
            'administration.maintenances.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            show:            {
                action:  (row) => this.appContainer.router.navigateToRoute('administration.maintenances.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['administration.maintenances.manage', 'administration.maintenances.view']),
            },
            edit:            {
                action:  (row) => this.appContainer.router.navigateToRoute('administration.maintenances.edit', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['administration.maintenances.manage', 'administration.maintenances.edit']),
            },
            destroy:         {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.appContainer.authenticatedUser.can(['administration.maintenances.manage', 'administration.maintenances.delete']),
            },
            options:         [
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportListing(this.i18N.tr('form.field.sectors'), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                    visible: (row) => this.appContainer.authenticatedUser.can(['administration.maintenances.manage', 'administration.maintenances.view']),
                },
            ],
            selectable:      true,
            destroySelected: true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'title',
                    name:  'maintenances.title',
                    title: 'form.field.title',
                },
                {
                    data:            'start_date',
                    name:            'maintenances.start_date',
                    title:           'form.field.start-date',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:            'end_date',
                    name:            'maintenances.end_date',
                    title:           'form.field.end-date',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:       'allowed_users',
                    name:       'maintenances.allowed_users',
                    title:      'form.field.allowed-users',
                    searchable: false,
                    orderable:  false,
                    type:       'processed',
                    processor:  this.joinAllowedUsers,
                },
                {
                    data:  'created_by',
                    name:  'users.name',
                    title: 'form.field.created-by',
                },
                {
                    data:            'created_at',
                    name:            'sector.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

    /**
     * Make list of allowed users
     *
     * @param row
     * @returns {string}
     */
    joinAllowedUsers(row) {
        var html = '<ul>';

        for (var name of row['allowed_users']) {
            html += '<li>';
            html += name;
            html += '</li>';
        }

        html += '</ul>';
        return html;
    }

}
