import { inject }              from 'aurelia-framework';
import { BooleanStatus }       from 'modules/administration/models/boolean-status';
import { Role }                from 'modules/administration/models/role';
import { RoleTypesRepository } from 'modules/administration/roles/role-types/services/repository';
import { MenusRepository }     from 'modules/administration/roles/services/menus-repository';

@inject(RoleTypesRepository, MenusRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{status_id: number}}
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param roleTypesRepository
     * @param menusRepository
     */
    constructor(roleTypesRepository, menusRepository) {
        this.roleTypesRepository = roleTypesRepository;
        this.menusRepository     = menusRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Role}
     */
    model() {
        let model = new Role();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.name = {
            type:  'text',
            key:   'name',
            label: 'form.field.description',
            size:  6,
        };

        this.status_id = {
            type:  'boolean-statuses-select',
            key:   'status_id',
            label: 'form.field.status',
            size:  6,
        };

        this.type_id = {
            type:         'select2',
            key:          'type_id',
            label:        'form.field.menus',
            size:         6,
            remoteSource: () => this.roleTypesRepository.all(),
            observers:    [
                (newValue, oldValue) => {
                    this.menu_ids.instance.disable(!newValue);

                    if (newValue) {
                        return this.menusRepository.allByRoleType(newValue).then((response) => {
                            this.menu_ids.options = response;
                            this.menu_ids.instance.enable(response.length);

                            this.menu_ids.instance.refreshLabels();
                        });
                    } else {
                        this.menu_ids.options = [];
                        this.menu_ids.instance.refreshLabels();
                    }
                },
            ],
        };

        this.description = {
            type:     'textarea',
            key:      'description',
            label:    'form.field.observations',
            size:     12,
            required: false,
        };

        this.menu_ids = {
            type:         'duallistbox',
            key:          'menu_ids',
            label:        'form.field.associated-menus',
            size:         12,
            required:     false,
            remoteSource: async () => {
                return viewModel.model.type_id
                    ? this.menusRepository.allByRoleType(viewModel.model.type_id)
                    : [{}];
            },
            attributes:   {
                disabled: !viewModel.model.type_id,
            },
            observers:    [
                (newValue) => {
                    if (!viewModel.create) {
                        viewModel.handlePermissionsShown();
                    }
                },
            ],
        };

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     (event) => viewModel.redirectToRoute('administration.roles.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     (event) => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.name, this.type_id],
            [this.status_id],
            [this.description],
            [this.menu_ids],
            [this.buttons],
        ];
    }

}
