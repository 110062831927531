import { inject }              from 'aurelia-framework';
import { I18N }                from 'aurelia-i18n';
import { BooleanStatus }       from 'modules/administration/models/boolean-status';
import { Role }                from 'modules/administration/models/role';
import { RoleType }            from 'modules/administration/models/role-type';
import { RoleTypesRepository } from 'modules/administration/roles/role-types/services/repository';
import { MenusRepository }     from 'modules/administration/roles/services/menus-repository';

@inject(I18N, RoleTypesRepository, MenusRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{status_id: number}}
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param i18N
     * @param roleTypesRepository
     * @param menusRepository
     */
    constructor(i18N, roleTypesRepository, menusRepository) {
        this.i18N                = i18N;
        this.roleTypesRepository = roleTypesRepository;
        this.menusRepository     = menusRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Role}
     */
    model() {
        let model = new Role();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.description',
            size:       6,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.status_id = {
            type:       'boolean-statuses-select',
            key:        'status_id',
            label:      'form.field.status',
            size:       6,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.type_id = {
            type:         'select2',
            key:          'type_id',
            label:        'form.field.menus',
            hidden:       !viewModel.model.type_id,
            size:         6,
            remoteSource: () => viewModel.model.type_id === RoleType.SYSTEM
                ? Promise.resolve([{ id: RoleType.SYSTEM, name: this.i18N.tr('form.field.system') }])
                : this.roleTypesRepository.all(),
            attributes:   {
                disabled: true,
            },
        };

        this.description = {
            type:       'textarea',
            key:        'description',
            label:      'form.field.observations',
            size:       12,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.menu_ids = {
            type:         'duallistbox',
            key:          'menu_ids',
            label:        'form.field.associated-menus',
            size:         12,
            required:     false,
            remoteSource: async () => {
                return (viewModel.model.type_id && viewModel.model.type_id !== RoleType.SYSTEM)
                    ? this.menusRepository.allByRoleType(viewModel.model.type_id)
                    : this.menusRepository.all();
            },
            attributes:   {
                disabled: true,
            },
        };

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('administration.roles.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
            ],
        };

        return [
            [this.name, this.type_id],
            [this.status_id],
            [this.description],
            [this.menu_ids],
            [this.buttons],
        ];
    }

}
