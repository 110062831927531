import { inject }            from 'aurelia-framework';
import { BaseFormViewModel } from 'base-form-view-model';
import { RolesRepository }   from 'modules/administration/roles/services/repository';
import { FormSchema }        from 'modules/administration/roles/view/form-schema';
import { AppContainer }      from 'resources/services/app-container';

@inject(AppContainer, RolesRepository, FormSchema)
export class ViewRole extends BaseFormViewModel {

    headerTitle     = 'form.title.view-record';
    editRecordRoute = 'administration.roles.edit';
    formId          = 'administration.roles.view-form';

    alert       = {};
    model       = {};
    schema      = {};
    permissions = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.roles.manage',
            'administration.roles.view',
        ]);
    }

    /**
     * Handles activate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.editRecordRoute = !this.model.sticky && this.appContainer.authenticatedUser.can(['administration.roles.manage', 'administration.roles.edit']) ? this.editRecordRoute : null;

            this.schema = this.formSchema.schema(this);
        });
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData(params) {
        return Promise.all([
            this.repository.find(params.id).then((response) => {
                this.initialModel.assign(response);
                this.model.assign(response);

                this.model.permissions.forEach((permission) => {
                    this.permissions.push(permission.id);
                });

                this.repository.allMenusWithPermissions().then((menusWithPermissions) => {
                    this.menusWithPermissions = menusWithPermissions;
                    this.handlePermissionsShown();
                });
            }),

            this.repository.activePermissionActions().then((response) => this.permissionActions = response),
        ]);
    }

    /**
     *
     * @param permissions
     * @param actionId
     *
     * @returns {*}
     */
    permissionIdByResourcePermissionsAndAction(permissions, actionId) {
        let permissionId = null;

        permissions.forEach((permission) => {
            if (permission.action_id === actionId) {
                permissionId = permission.id;
            }
        });

        return permissionId;
    }

    /**
     * Only shows permissions for the selected modules
     */
    handlePermissionsShown() {
        this.menus = this.menusWithPermissions.filter((menu) => this.model.menu_ids.includes(menu.id));
    }
}
