import { BaseModel } from 'modules/core/models/base-model';

export class Setting extends BaseModel {

    id                        = null;
    name                      = null;
    slug                      = null;
    backend_type              = null;
    frontend_type             = null;
    has_options               = null;
    options_repository        = null;
    options_repository_method = null;
    default_value             = null;
    value                     = null;
    mime_types                = null;
}
