import { BaseRepository } from 'modules/core/services/base-repository';

export class MenusRepository extends BaseRepository {
    baseUrl = 'administration/menus';

    /**
     * Fetches active menus with permission from server by role type
     */
    allByRoleType(typeId) {
        return this.httpClient.get(`${this.baseUrl}/${typeId}/all`);
    }

    /**
     * Fetches active menus with permission from server
     */
    withPermissions() {
        return this.httpClient.get(`${this.baseUrl}/with-permissions`);
    }

    /**
     * Fetches active menus with permission from server by role type
     */
    withPermissionsByRoleType(typeId) {
        return this.httpClient.get(`${this.baseUrl}/${typeId}/with-permissions`);
    }
}
