import { BaseModel } from 'modules/core/models/base-model';

const SYSTEM       = 1,
      GENERAL      = 2,
      CONSTRUCTION = 3;

export class RoleType extends BaseModel {

    id = null;

    static get SYSTEM() {
        return SYSTEM;
    }

    static get GENERAL() {
        return GENERAL;
    }

    static get CONSTRUCTION() {
        return CONSTRUCTION;
    }
}
