import { inject }            from 'aurelia-framework';
import { BaseFormViewModel } from 'base-form-view-model';
import { FormSchema }        from 'modules/administration/roles/form-schema';
import { RolesRepository }   from 'modules/administration/roles/services/repository';
import { AppContainer }      from 'resources/services/app-container';

@inject(AppContainer, RolesRepository, FormSchema)
export class CreateRole extends BaseFormViewModel {

    headerTitle = 'form.title.create-new-record';
    formId      = 'administration.roles.creation-form';

    alert  = {};
    model  = {};
    schema = {};

    create = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultFormView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.roles.manage',
            'administration.roles.create',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.schema       = this.formSchema.schema(this);
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        this.repository.create(this.model)
            .then(response => {
                if (response.status === true) {
                    this.appContainer.notifier.successNotice(response.message);

                    this.initialModel.assign(this.model);

                    this.redirectToRoute({ name: 'administration.roles.edit', params: { id: response.model.id } }, 500);
                } else {
                    this.alert = this.alertMessage(response.status, response.message, response.errors);
                }
            }).catch(console.error.bind(console));
    }
}
