import { inject }                   from 'aurelia-framework';
import { PLATFORM }                 from 'aurelia-pal';
import { BaseFormViewModel }        from 'base-form-view-model';
import { FormSchema }               from 'modules/administration/resource-fields/form-schema';
import { ResourceFieldsRepository } from 'modules/administration/resource-fields/services/repository';
import { AppContainer }             from 'resources/services/app-container';

@inject(AppContainer, ResourceFieldsRepository, FormSchema)
export class ViewResourceField extends BaseFormViewModel {
    headerTitle = 'form.title.view-record';
    formId      = 'administration.resource-fields.view-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/views/default/button-form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.resource-fields.manage',
            'administration.resource-fields.view',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        await this.fetchData(params);
        this.schema       = this.formSchema.schema(this, true);
        this.globalSchema = this.formSchema.globalSchema(this, true);
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData({ id }) {
        const response = await this.repository.find(id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }
}
