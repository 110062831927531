import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class MaintenancesRepository extends BaseRepository {
    baseUrl = 'administration/maintenances';

    /**
     * Fetches the maintenances that are scheduled to start in the future
     *
     * @returns {*}
     */
    scheduled() {
        return this.httpClient.get(`${this.baseUrl}/scheduled`);
    }

    /**
     * Fetches the ongoing maintenances
     *
     * @returns {*}
     */
    ongoing() {
        return this.httpClient.get(`${this.baseUrl}/ongoing`);
    }

    /**
     * Toggles the maintenance status
     *
     * @returns {*}
     */
    toggleMaintenance() {
        return this.httpClient.get(`${this.baseUrl}/toggle`);
    }
}
