import { BaseModel } from 'modules/core/models/base-model';

const HIGHWAY_OPERATOR = 1,
      REGULATOR        = 2,
      BUILDER          = 3;

export class UserEntityTeamLotType extends BaseModel {

    id = null;

    static get HIGHWAY_OPERATOR() {
        return HIGHWAY_OPERATOR;
    }

    static get REGULATOR() {
        return REGULATOR;
    }

    static get BUILDER() {
        return BUILDER;
    }
}
