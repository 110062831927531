import { BaseModel } from 'modules/core/models/base-model';

const GLOBAL_ADMINISTRATOR              = 1;

export class Role extends BaseModel {

    name            = null;
    slug            = null;
    description     = null;
    entity_type_id  = null;
    system_reserved = null;
    status_id       = null;
    permissions     = [];
    menu_ids        = [];
    menus           = [];
    type_id         = null;

    static get GLOBAL_ADMINISTRATOR() {
        return GLOBAL_ADMINISTRATOR;
    }
}
